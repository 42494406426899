import React, { useEffect, useState } from "react";
import "./styles/SearchBar.css";
import { Button, IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
const SearchBar = (props) => {
  const [userInput, setUserInput] = useState("");

  function searchExams(event) {
    event.preventDefault();
    let result = props.exams.filter((exam) => {
      if (exam.name.toLowerCase().includes(userInput.toLowerCase())) {
        return true;
      } else if (
        exam.description.toLowerCase().includes(userInput.toLowerCase())
      ) {
        return true;
      }
      return result;
    });
    props.setExams(result);
  }

  useEffect(() => {
    if (userInput === "") {
      props.getExams();
    }
  }, [userInput]);
  return (
    <div className="search-bar">
      {" "}
      <form onSubmit={searchExams} style={{ width: "inherit" }}>
        <div className="search">
          <input
            value={userInput}
            name="search-bar"
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Search..."
            type="text"
            autoComplete="off"
            list="autocompleteOff"
          />
          <IconButton onClick={searchExams}>
            <Search />
          </IconButton>
        </div>
      </form>
      <div className="desktop">
        <Button
          href="/exam/new"
          variant="contained"
          disableElevation
          className="px-4 button-link"
          sx={{ height: "3rem", minWidth: "max-content" }}
          color="primary"
          startIcon={<AddIcon color="white" />}
        >
          New Exam
        </Button>
      </div>
    </div>
  );
};

export default SearchBar;

import React from "react";
import LoginContent from "../../components/UserAccess/LoginContent";

const LoginPage = () => {
  return (
    <>
      <LoginContent />
    </>
  );
};

export default LoginPage;

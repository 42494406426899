import * as request from "../axios";

export async function toggleStudy(id, data, token) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/${id}/`,
      data,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteCard(id, token) {
  try {
    await request.axiosDelete(
      `https://rezin.pythonanywhere.com/cards/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteCategory(id, token) {
  try {
    await request.axiosDelete(
      `https://rezin.pythonanywhere.com/cards/categories/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function deleteDeck(id, token) {
  try {
    await request.axiosDelete(
      `https://rezin.pythonanywhere.com/cards/decks/${id}/`,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateCategory(id, token, name) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/categories/${id}/`,
      {
        name: name,
      },
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function updateDeck(id, token, name) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/decks/${id}/`,
      {
        name: name,
      },
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addCategoryOrDeck(type, token, name, category = null) {
  const body = {
    name: name,
  };
  if (category) {
    body.category_id = category;
  }
  try {
    return await request.axiosPost(
      `https://rezin.pythonanywhere.com/cards/${type}/`,
      body,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addCard(card, token) {
  try {
    return await request.axiosPost(
      "https://rezin.pythonanywhere.com/cards/",
      card,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function editCard(id, token, card) {
  try {
    return await request.axiosPut(
      `https://rezin.pythonanywhere.com/cards/${id}/`,
      card,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function addFlashCardsCSV(token, file, deck_id) {
  try {
    return await request.axiosPostWithFile(
      `https://rezin.pythonanywhere.com/cards/csv/${deck_id}`,
      file,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function parseCSV(token, file) {
  try {
    return await request.axiosGetWithFile(
      "https://rezin.pythonanywhere.com/cards/csv/",
      file,
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export default async function getAllCards(token, filter) {
  const filterParams = {};
  if (filter.deck) filterParams.deck = filter.deck;
  if (filter.category) filterParams.category = filter.category;
  if (filter.shuffle === true) filterParams.shuffle = true;
  if (filter.study === true) filterParams.study = true;
  if (filter.wrong === true) filterParams.wrong = true;

  try {
    return await request.axiosGet(
      "https://rezin.pythonanywhere.com/cards/",
      token,
      filterParams
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAllCategories(token) {
  try {
    return await request.axiosGet(
      "https://rezin.pythonanywhere.com/cards/categories/",
      token
    );
  } catch (error) {
    console.log(error);
  }
}

export async function getAllDecks(token) {
  try {
    return await request.axiosGet(
      "https://rezin.pythonanywhere.com/cards/decks/",
      token
    );
  } catch (error) {
    console.log(error);
  }
}

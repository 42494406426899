import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import DashboardView from "../../components/DisplayItems/DashboardView";
import FilterCards from "../../components/Search/FilterCards";
import CardView from "../../components/DisplayItems/CardView";
import { useLocation } from "react-router-dom";
import getAllCards, {
  getAllCategories,
  getAllDecks,
} from "../../endpoints/FlashCards/functions";
import Footer from "../../components/Custom/Footer";
import { IconButton } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
import AddDeckCategory from "../../components/Modals/AddDeckCategory";
const FlashHome = (props) => {
  const [decks, setDecks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cards, setCards] = useState([]);
  const [modalOpen, setModalOpen] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const initialDeck = searchParams.get("deck") || "";
  const initialCategory = searchParams.get("category") || "";
  const initialStudy = searchParams.get("study") === "true";
  const initialShuffle = searchParams.get("shuffle") === "true";

  const [filter, setFilter] = useState({
    category: initialCategory,
    deck: initialDeck,
    shuffle: initialShuffle,
    study: initialStudy,
  });
  async function getDecks() {
    try {
      let result = await getAllDecks(props.token);
      setDecks(result);
    } catch (error) {
      console.log("Error at getDecks", error);
    }
  }

  async function getCategories() {
    try {
      let result = await getAllCategories(props.token);
      setCategories(result);
    } catch (error) {
      console.log("Error at getCategories", error);
    }
  }
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getDecks();
      setLoading(false);
      await getCategories();
    }
    fetchData();
  }, [props.user]);

  useEffect(() => {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const deck = searchParams.get("deck") || "";
    const category = searchParams.get("category") || "";
    const study = searchParams.get("study") === "true";
    const shuffle = searchParams.get("shuffle") === "true";

    setFilter({
      deck,
      category,
      shuffle,
      study,
    });
  }, [location.search]);

  async function getCards(filters) {
    try {
      let data = await getAllCards(props.token, filters);
      setCards(data);
    } catch (error) {
      console.log("Error at getCards", error);
    }
  }
  return (
    <div className="page" id="flashcards">
      <NavBar
        isFlashHome={true}
        getDecks={getDecks}
        getCards={getCards}
        filter={filter}
        getCategories={getCategories}
        exams={[]}
        user={props.user}
        token={props.token || localStorage.getItem("token")}
      />
      <div className="page-box">
        <div className="flash-top">
          <FilterCards
            decks={decks}
            cards={cards}
            categories={categories}
            filter={filter}
            setFilter={setFilter}
            token={props.token || localStorage.getItem("token")}
            getCards={getCards}
          />
        </div>
        {filter.category !== "" ||
        filter.deck !== "" ||
        filter.study ||
        filter.wrong ? (
          <CardView
            cards={cards}
            token={props.token || localStorage.getItem("token")}
            getCards={getCards}
            categories={categories}
            decks={decks}
            filter={filter}
          />
        ) : (
          <DashboardView
            categories={categories}
            decks={decks}
            loading={loading}
            token={props.token || localStorage.getItem("token")}
            getDecks={getDecks}
            getCategories={getCategories}
            filter={filter}
            setFilter={setFilter}
          />
        )}
        <Footer />
      </div>
      <IconButton
        onClick={() => setModalOpen("active")}
        className="button-link fab"
        color="primary"
        sx={{ position: "fixed", bottom: 2, right: 2, zIndex: 100 }}
        disableFocusRipple
      >
        <AddCircleRounded color="primary" sx={{ fontSize: 50 }} />
      </IconButton>
      <AddDeckCategory
        token={props.token || localStorage.getItem("token")}
        getDecks={getDecks}
        getCategories={getCategories}
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        categories={categories}
      />
    </div>
  );
};

export default FlashHome;

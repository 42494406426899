export function defaultExamData(userId) {
  return {
    id: null,
    creator_id: userId,
    logo: "",
    name: "",
    description: "",
  };
}

export function setNewExamData(id, exam) {
  return {
    ...exam,
    id: id,
  };
}

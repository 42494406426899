import React, { useState } from "react";
import DisplayExams from "../../components/DisplayItems/DisplayExams";
import NavBar from "../../components/NavBar/NavBar";
import SearchBar from "../../components/Search/SearchBar";
import { getAllExams } from "../../endpoints/Assessment/functions";
import { IconButton } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
const HomePage = (props) => {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getExams() {
    setLoading(true);
    try {
      let data = await getAllExams(props.token);
      setExams(data);
    } catch (error) {
      console.log("Error at getExams", error);
    }
    setLoading(false);
  }
  return (
    <div className="page">
      <NavBar isFlashHome={false} user={props.user} token={props.token} />
      <div className="page-box">
        <div>
          <SearchBar
            exams={exams}
            setExams={setExams}
            getExams={getExams}
            user={props.user}
            token={props.token}
          />
        </div>
        <div>
          {" "}
          <DisplayExams
            loading={loading}
            user={props.user}
            token={props.token}
            getExams={getExams}
            exams={exams}
          />
        </div>{" "}
        <IconButton
          href="/exam/new"
          className="mobile button-link fab"
          color="primary"
          sx={{ position: "fixed", bottom: 2, right: 2 }}
          disableFocusRipple
        >
          <AddCircleRounded color="primary" sx={{ fontSize: 50 }} />
        </IconButton>
      </div>
    </div>
  );
};

export default HomePage;

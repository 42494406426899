import React, { useState } from "react";
import Teleport from "../Custom/Teleport";
import { ClipLoader } from "react-spinners";
import { Delete } from "@mui/icons-material";
const DeleteModal = (props) => {
  const [showDelete, setShowDelete] = useState("");
  function toggle() {
    if (showDelete === "active") {
      setShowDelete("");
    } else {
      setShowDelete("active");
    }
  }
  return (
    <>
      <button
        onClick={() => setShowDelete("active")}
        title="Delete"
        className="popup"
      >
        <Delete color="error" alt="Delete" fontSize="medium" />
      </button>
      <Teleport>
        <div className={showDelete} id="delete-modal">
          <div className="header">
            <h1>{props.title}</h1>
            <hr />
            <button onClick={() => toggle()} className="close-modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to delete {props.name}? {props.message}
            <div className="option-buttons">
              <button
                className="modal-save"
                onClick={() => props.deleteFunction(props.id)}
                disabled={props.loading}
              >
                {!props.loading ? (
                  "DELETE"
                ) : (
                  <ClipLoader
                    color={"#ffffff"}
                    loading={props.loading}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </button>
              <button onClick={() => toggle()} className="cancel">
                CANCEL
              </button>
            </div>
          </div>
        </div>
        <div className={showDelete} id="delete-overlay"></div>
      </Teleport>
    </>
  );
};

export default DeleteModal;

import React from "react";
import "./styles/DisplayExams.css";
import ExamImage from "../../utils/placeholder.jpeg";
import { ClipLoader } from "react-spinners";
import ExamCard from "./ExamCard";
import Footer from "../Custom/Footer";
const DisplayExams = (props) => {
  if (props.loading) {
    return (
      <div className="around-no-exams">
        <div id="loading" className="exams-container">
          <ClipLoader
            color={"var(--red"}
            loading={props.loading}
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  } else if (!props.exams.length && !props.loading) {
    return (
      <div className="around-no-exams">
        <div id="no-exams" className="exams-container">
          <h1>No Exams Found</h1>
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <>
      <div className="around-exams">
        <div className="exams-container">
          {props.exams.map((exam) => {
            const examLogo = exam.logo ? exam.logo : ExamImage;
            return (
              <div key={exam.id}>
                <ExamCard
                  examLogo={examLogo}
                  exam={exam}
                  getExams={props.getExams}
                  token={props.token}
                  user={props.user}
                />
              </div>
            );
          })}
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default DisplayExams;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getAllCards from "../../endpoints/FlashCards/functions";
import PlayCards from "../../components/PlayContent/Cards/PlayCards";
const PlayHome = (props) => {
  const location = useLocation();
  const filter = location.state && location.state.filter;
  const navigate = useNavigate();
  const [playingCards, setPlayingCards] = useState([]);
  const [index, setIndex] = useState(0);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (!filter || !props.token) {
      return navigate("/flashcards");
    }
    const fetchCards = async () => {
      try {
        await getCards(filter);
      } catch (error) {
        console.log("Error occured at PlayHome mount", error);
      }
    };
    fetchCards();
  }, [filter]);

  async function getCards(filter) {
    try {
      let data = await getAllCards(props.token, filter);
      setPlayingCards(data);
      setTotal(data.length);
    } catch (error) {
      console.log("Error at getCards at PlayHome", error);
    }
  }
  return (
    <PlayCards
      total={total}
      playingCards={playingCards}
      index={index}
      setIndex={setIndex}
      token={props.token}
    />
  );
};

export default PlayHome;

import React, { useState } from "react";
import "./styles/Modals.css";
import LockIcon from "@mui/icons-material/Lock";
import Teleport from "../Custom/Teleport";
import { updateProfilePassword } from "../../endpoints/Assessment/functions";
import { Button, TextField, Tooltip } from "@mui/material";
const ProfileModal = (props) => {
  const [profileModal, setProfileModal] = useState("");
  const [newPass, setNewPass] = useState({
    newEntry: "",
    reEntry: "",
    error: "",
    strong: null,
  });

  function toggleProfileModal() {
    if (profileModal === "active") {
      setProfileModal("");
      setNewPass({ newEntry: "", reEntry: "", error: "", strong: null });
    } else {
      setProfileModal("active");
    }
  }

  async function changePassword() {
    var pattern = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
    let strongPass = pattern.test(newPass["newEntry"]);
    if (
      newPass["newEntry"] !== "" &&
      newPass["reEntry"] !== "" &&
      newPass["newEntry"] === newPass["reEntry"] &&
      strongPass
    ) {
      let userData = {
        username: props.user.username,
        password: newPass.newEntry,
        email: props.user.email,
        first_name: props.user.first_name,
        last_name: props.user.last_name,
      };
      try {
        await updateProfilePassword(props.token, userData);
      } catch (error) {
        console.log("Error occured at changePassword", error);
      }
      setNewPass({
        ...newPass,
        newEntry: "",
        reEntry: "",
        error: "",
        strong: null,
      });
      toggleProfileModal();
    } else if (
      !strongPass &&
      newPass["newEntry"] !== "" &&
      newPass["reEntry"] !== "" &&
      newPass["newEntry"] === newPass["reEntry"]
    ) {
      setNewPass({ ...newPass, error: "not-a-match", strong: false });
    } else {
      setNewPass({
        ...newPass,
        newEntry: "",
        reEntry: "",
        error: "not-a-match",
      });
    }
  }
  return (
    <div>
      <div>
        <button onClick={() => toggleProfileModal()}>
          <Tooltip
            title={props.navBar === "active" ? "" : "Change Password"}
            placement="right"
          >
            <LockIcon color="black" className="icon" />
          </Tooltip>
          <p hidden={props.navBar === ""} className="description">
            Change Password
          </p>{" "}
        </button>
      </div>
      <Teleport>
        <div className={profileModal} id="profile-modal">
          <div className="header">
            <h1>change password</h1>
            <hr />
            <button
              onClick={() => toggleProfileModal()}
              className="close-modal"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div>
              <TextField
                name="new password"
                color={
                  newPass.newEntry === newPass.reEntry &&
                  newPass.newEntry !== ""
                    ? "success"
                    : "black"
                }
                focused={
                  newPass.newEntry === newPass.reEntry &&
                  newPass.newEntry !== ""
                }
                label="New Password"
                type="password"
                value={newPass.newEntry}
                onChange={(e) =>
                  setNewPass({ ...newPass, newEntry: e.target.value })
                }
                error={newPass.newEntry !== newPass.reEntry}
              />
              <TextField
                name="new password reentry"
                color={
                  newPass.newEntry === newPass.reEntry && newPass.reEntry !== ""
                    ? "success"
                    : "black"
                }
                focused={
                  newPass.newEntry === newPass.reEntry && newPass.reEntry !== ""
                }
                label="Confirm Password"
                type="password"
                value={newPass.reEntry}
                onChange={(e) =>
                  setNewPass({ ...newPass, reEntry: e.target.value })
                }
                error={newPass.newEntry !== newPass.reEntry}
              />
              <Button
                onClick={() => changePassword()}
                color="black"
                sx={{ height: "3.5rem" }}
                variant="contained"
                className="px-4"
                disableElevation
                disabled={
                  newPass.newEntry === "" ||
                  newPass.reEntry === "" ||
                  newPass.reEntry !== newPass.newEntry
                }
              >
                submit
              </Button>
            </div>
            {newPass.newEntry !== newPass.reEntry && newPass.strong === null ? (
              <p className="match-error">
                'New Password' needs to match 'Confirm Password'
              </p>
            ) : null}
            {newPass.strong === false ? (
              <p className="match-error">
                Your password needs special characters, numbers and letters. Try
                Again
              </p>
            ) : null}
          </div>
        </div>
        <div className={profileModal} id="profile-overlay"></div>
      </Teleport>
    </div>
  );
};

export default ProfileModal;

import React, { useContext, useState } from "react";
import "./NavBar.css";
import logo from "../../Logos/logo.png";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import ProfileModal from "../Modals/ProfileModal";

import {
  ArticleRounded,
  HelpCenterRounded,
  LogoutRounded,
  Style,
} from "@mui/icons-material";
import { Button, PopoverPaper, Tooltip } from "@mui/material";
import Teleport from "../Custom/Teleport";

const NavBar = (props) => {
  const { logoutUser } = useContext(AuthContext);
  const [navBar, setNavBar] = useState("");
  const mobileMenu = document.getElementsByClassName("mobile-menu")[0];
  const [hamburger, setHamburger] = useState("hamburger");

  function toggleMenu(screen) {
    if (screen === "desktop") {
      if (navBar === "") {
        setNavBar("active");
        setHamburger("hamburger open");
      } else {
        setNavBar("");
        setHamburger("hamburger");
      }
    } else if (screen === "mobile") {
      if (mobileMenu.hidden === true) {
        mobileMenu.hidden = false;
        setHamburger("hamburger open");
      } else {
        mobileMenu.hidden = true;
        setHamburger("hamburger");
      }
    }
  }

  // TODO: Need to refactor and move to add deck cards
  // function downloadCSV(uri, name) {
  //   var link = document.createElement("a");
  //   link.download = name;
  //   link.href = uri;
  //   link.click();
  // }

  // TODO: Need to move to new add deck logic
  // function handleSharedDoc() {
  //   downloadCSV(
  //     "https://docs.google.com/spreadsheets/d/1P_Xs24AJ_tVRLn9XQGHbft1lVT_1qO16ACVWaSqHmVI/export?gid=0&format=csv",
  //     "new_flashcards_upload"
  //   );
  // }
  return (
    <div className="position-relative">
      <ul id="nav-bar" className={navBar} style={{ paddingLeft: "0" }}>
        <li className="part-one">
          <Link to={props.isFlashHome ? "/flashcards" : "/"}>
            <img
              src={logo}
              alt="Assessive Logo"
              className={props.isFlashHome ? "logo flash-card-logo" : "logo"}
            />
          </Link>

          <Tooltip
            title={navBar === "active" ? "" : "Toggle Menu"}
            placement="right"
          >
            <button
              onClick={() => toggleMenu("desktop")}
              id="menu-btn"
              className={hamburger}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </button>
          </Tooltip>
        </li>
        <li className="part-two">
          <ProfileModal user={props.user} token={props.token} navBar={navBar} />
          {props.isFlashHome ? (
            <>
              <Link to="/">
                <Tooltip
                  title={navBar === "active" ? "" : "Switch To Exams"}
                  placement="right"
                  disabled={navBar === "active"}
                >
                  <ArticleRounded color="black" className="icon" />
                </Tooltip>
                <p hidden={navBar === ""} className="description">
                  Switch to Exams
                </p>
              </Link>
            </>
          ) : (
            <Link to="/flashcards">
              <Tooltip
                title={navBar === "active" ? "" : "Switch To Cards"}
                placement="right"
                disabled={navBar === "active"}
              >
                <Style
                  color="black"
                  className="icon"
                  sx={{ transform: "rotate(90deg)" }}
                />
              </Tooltip>
              <p hidden={navBar === ""} className="description">
                Switch To Cards
              </p>
            </Link>
          )}
          <a
            href={`https://www.renyreyesportfolio.com/projects/${
              props.isFlashHome ? "p13" : "p8"
            }`}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Help Video"
          >
            <Tooltip
              title={navBar === "active" ? "" : "Tutorial Video"}
              placement="right"
            >
              <HelpCenterRounded color="black" className="icon" />
            </Tooltip>
            <p hidden={navBar === ""} className="description">
              Tutorial Video
            </p>
          </a>
        </li>
        <li className="part-three">
          <button title="Log Out" onClick={logoutUser} className="desktop">
            <Tooltip
              title={navBar === "active" ? "" : "Log Out"}
              placement="right"
            >
              <LogoutRounded color="black" className="icon" />
            </Tooltip>
          </button>

          <button
            onClick={() => toggleMenu("mobile")}
            id="menu-btn-mobile"
            className={hamburger}
            title="Toggle Menu"
            aria-labelledby="menu-btn-mobile"
          >
            <span className="hamburger-top"></span>
            <span className="hamburger-middle"></span>
            <span className="hamburger-bottom"></span>
          </button>
          <Teleport>
            <PopoverPaper
              className="mobile-menu"
              hidden
              square={false}
              sx={{ boxShadow: "var(--box-shadow)", position: "fixed" }}
              onClose={() => toggleMenu("mobile")}
            >
              <ul>
                <li>
                  {!props.isFlashHome ? (
                    <Link to="/flashcards" className="menu-link">
                      Switch To Cards
                    </Link>
                  ) : (
                    <Link to="/" className="menu-link">
                      Switch to Exams
                    </Link>
                  )}
                </li>
                <li>
                  <a
                    href={`https://www.renyreyesportfolio.com/projects/${
                      props.isFlashHome ? "p13" : "p8"
                    }`}
                    className="menu-link"
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Help Video"
                  >
                    Tutorial Video
                  </a>
                </li>
                <li className="no-border">
                  <Button
                    title="Log Out"
                    onClick={logoutUser}
                    className="mobile px-4"
                    size="small"
                    fullWidth
                    disableElevation
                    sx={{
                      color: "white",
                      borderRadius: 50,
                      boxShadow: "none",
                      fontSize: "10px",
                    }}
                    variant="contained"
                    color="black"
                  >
                    Log Out
                  </Button>
                </li>
              </ul>
            </PopoverPaper>
          </Teleport>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;

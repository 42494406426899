import React, { useState } from "react";
import "./styles/CardView.css";
import ModifyCard from "../Modals/ModifyCard";
import DeleteModal from "../Modals/DeleteModal";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import { deleteCard, toggleStudy } from "../../endpoints/FlashCards/functions";
const Cards = (props) => {
  const [flip, setFlip] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  async function handleToggleStudy(card) {
    const body = {
      deck_id: card.deck.id,
      question: card.question,
      answer: card.answer,
      hint: card.hint,
      in_study_pile: !card.in_study_pile,
    };
    try {
      await toggleStudy(card.id, body, props.token);
      await props.getCards(props.filter);
    } catch (error) {
      console.log("Error occured at handleToggleStudy", error);
    }
  }

  async function handleDeleteCard(id) {
    setLoadingSave(true);
    try {
      await deleteCard(id, props.token);
      await props.getCards(props.filter);
    } catch (error) {
      console.log("Error occured at handleDeleteCard", error);
    }
    setLoadingSave(false);
  }
  return (
    <div className="card-container">
      <div className="flash-card" onClick={() => setFlip(!flip)}>
        {!flip ? (
          <div className="card-front">
            <p>{props.card.question}</p>
          </div>
        ) : (
          <div className="card-back">
            <h3>{props.card.answer}</h3>
          </div>
        )}
      </div>
      <div className="cta-buttons">
        <button
          title={
            props.card.in_study_pile ? "Remove From Study" : "Add To Study"
          }
          onClick={() => handleToggleStudy(props.card)}
        >
          {props.card.in_study_pile ? (
            <LocalLibraryIcon sx={{ color: "#0b4f6c", fontSize: "1.7rem" }} />
          ) : (
            <LocalLibraryOutlinedIcon
              sx={{ color: "#0b4f6c", fontSize: "1.7rem" }}
            />
          )}
        </button>
        <ModifyCard
          card={props.card}
          categories={props.categories}
          decks={props.decks}
          token={props.token}
          getCards={props.getCards}
          filter={props.filter}
        />
        <DeleteModal
          title="DELETE CARD"
          id={props.card.id}
          deleteFunction={handleDeleteCard}
          loading={loadingSave}
          name={"this card"}
          message=""
        />
      </div>
    </div>
  );
};

export default Cards;

import React, { useState } from "react";
import "./StartExamContent.css";
import Logo from "../../../Logos/logo.png";
import { useNavigate } from "react-router-dom";
import miniLogo from "../../../Logos/icon.png";
import { HomeRounded } from "@mui/icons-material";
import { Button, IconButton, Radio, Tooltip } from "@mui/material";
const StartExamContent = (props) => {
  let choiceButtons = document.getElementsByClassName("choices");
  const [alertResult, setAlertResult] = useState("");
  const [currentCount, setCurrentCount] = useState(1);
  const [validateFunctionCalled, setValidateFunctionCalled] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [answer, setAnswer] = useState();
  const navigate = useNavigate();

  function exitExam() {
    let confirmation = window.confirm(
      "Click OK if you are sure you would like to end your exam session"
    );
    if (confirmation) {
      navigate("/");
    }
  }

  async function setScore() {
    if (answer && answer.correct) {
      setFinalScore(finalScore + 1);
      setAlertResult("correct");
    } else {
      setAlertResult("wrong");
    }
    setValidateFunctionCalled(true);
  }

  async function validate() {
    setScore();
    for (let x = 0; x < choiceButtons.length; x++) {
      if (choiceButtons[x].id.includes("correct")) {
        choiceButtons[x].style.border = "var(--green) solid 1.5px";
      } else {
        choiceButtons[x].style.border = "var(--red) solid 1.5px";
      }
    }
  }
  function changeQuestion(currentIndex) {
    if (!validateFunctionCalled) setScore();
    setAnswer("");
    setAlertResult("");
    setValidateFunctionCalled(false);

    if (currentCount <= props.count) {
      const nextQuestion = currentIndex + 1;
      props.setCurrentQuestion(nextQuestion);
      setCurrentCount(currentCount + 1);
      for (let x = 0; x < choiceButtons.length; x++) {
        choiceButtons[x].style.border = "#c7c7c7 solid 1px";
      }
    }
  }

  function restartExam() {
    setCurrentCount(1);
    setFinalScore(0);
    setAnswer("");
    setAlertResult("");
    setValidateFunctionCalled(false);
    props.setCurrentQuestion(0);
  }
  return (
    <div className="active-container">
      <div className="around-active-exam">
        <div className="top-part">
          <img className="logo-image" src={Logo} alt="Assessive Logo" />
          <div id="result-alert" className={alertResult}></div>
          <Tooltip title="Exit Exam" placement="left">
            <IconButton onClick={() => exitExam()} className="exit-button">
              <HomeRounded fontSize="medium" />
            </IconButton>
          </Tooltip>
        </div>
        {currentCount <= props.count ? (
          <div className="middle-part">
            <h6 className="question">
              {props.allQuestions[props.currentQuestion]
                ? props.allQuestions[props.currentQuestion].question
                : ""}
            </h6>
            {props.allQuestions[props.currentQuestion] &&
              props.allQuestions[props.currentQuestion].choices.map(
                (item, index) => {
                  return (
                    <button
                      disabled={validateFunctionCalled}
                      onClick={() => setAnswer(item)}
                      className={`choices ${
                        answer === item ? "highlighted" : ""
                      }`}
                      id={`choice-${index}-${
                        item.correct ? "correct" : "wrong"
                      }`}
                      key={index.toString()}
                    >
                      <div>
                        <p style={{ marginBottom: "0rem", color: "#1e1e1e" }}>
                          {" "}
                          <Radio
                            color="black"
                            sx={{ padding: "0px", marginRight: "0.5rem" }}
                            size="small"
                            value={item}
                            checked={answer === item}
                            onChange={(e) => setAnswer(item)}
                          />
                          {item.choice}
                        </p>
                      </div>
                    </button>
                  );
                }
              )}{" "}
          </div>
        ) : (
          <div className="middle-part">
            <h4 className="final">
              <b>
                You got {finalScore} out of {props.count} correct.
              </b>
            </h4>
            <h4 className="final">
              <b>Your final score is:</b>
            </h4>
            <h4 className="final">
              <b>{((finalScore / props.count) * 100).toFixed(0)}%</b>
            </h4>
          </div>
        )}

        <div className="bottom-part">
          {currentCount > props.count ? (
            <a className="around-stamp" href="/register">
              <p className="logo-stamp" style={{ marginBottom: "0rem" }}>
                <img src={miniLogo} alt="Logo" />
                <b>Assessive</b>
              </p>
            </a>
          ) : (
            <Button
              onClick={() => validate()}
              color="iconWhite"
              variant="outlined"
              disableElevation
            >
              check
            </Button>
          )}
          <h6
            style={{
              marginBottom: "0rem",
              color: "white",
              textTransform: "lowercase",
            }}
          >
            {currentCount <= props.count ? currentCount : props.count} of{" "}
            {props.count}
          </h6>
          {currentCount <= props.count ? (
            <Button
              disableElevation
              onClick={() => changeQuestion(props.currentQuestion)}
              color="iconWhite"
              disabled={!answer && !validateFunctionCalled}
              variant={
                !answer && !validateFunctionCalled ? "contained" : "outlined"
              }
            >
              continue
            </Button>
          ) : (
            <Button
              onClick={() => restartExam()}
              color="iconWhite"
              variant="outlined"
              disableElevation
            >
              try again
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StartExamContent;

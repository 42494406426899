import React, { useState } from "react";

const Title = ({ text, onEdit, id, isEditing }) => {
  const [editedText, setEditedText] = useState(text);

  const handleBlur = () => {
    onEdit(id, editedText);
  };

  const handleInputChange = (e) => {
    setEditedText(e.target.value);
  };

  return (
    <div>
      {!isEditing ? (
        <h5 style={{ marginBottom: "0" }}>{editedText}</h5>
      ) : (
        <input
          style={{
            padding: "0",
            height: "fit-content",
            fontSize: "1.25rem",
            textAlign: "center",
          }}
          type="text"
          maxLength="19"
          value={editedText}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={(e) => (e.key === "Enter" ? handleBlur() : null)}
          autoFocus
        />
      )}
    </div>
  );
};
export default Title;

import React from "react";

const Footer = () => {
  return (
    <div id="footer">
      <footer>© {new Date().getFullYear()}, Assessive</footer>
    </div>
  );
};

export default Footer;

import { useEffect, useState } from "react";

const useCustomForm = (initialValues = {}, onSubmit) => {
  const [formData, setFormValues] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.clear();
  }, [onSubmit]);

  const handleInputChange = (e) => {
    e.persist();
    if (e.target.name === "isStudent") {
      setFormValues({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormValues({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (initialValues === formData) {
      alert("Invalid Entry. No information was entered. Try again.");
      console.clear();
      return;
    }
    setLoading(true);
    onSubmit(formData).then(() => {
      setLoading(false);
    });
  };

  const reset = () => {
    setFormValues(initialValues);
  };

  return [formData, handleInputChange, handleSubmit, reset, loading];
};

export default useCustomForm;

import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import useCustomForm from "../../hooks/useCustomForm";
import Logo from "../../Logos/logo.png";
import "./UserAccess.css";
import { ClipLoader } from "react-spinners";
import { Button, TextField } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
const LoginContent = () => {
  const { loginUser, isServerError } = useContext(AuthContext);
  const [passwordType, setPasswordType] = useState("password");
  const defaultValues = { username: "", password: "" };
  const [
    formData,
    handleInputChange,
    handleSubmit,
    reset,
    loading,
  ] = useCustomForm(defaultValues, loginUser);

  useEffect(() => {
    if (isServerError) {
      reset();
    }
  }, [isServerError]);

  function showPassword() {
    setPasswordType((prev) => (prev === "password" ? "text" : "password"));
  }
  return (
    <span className="access-container">
      <div className="form-container">
        <form onSubmit={handleSubmit} name="login-form">
          <div className="access-header">
            <h2 className="form-items">login</h2>
            <div>
              <img src={Logo} alt="Assessive Logo" />
            </div>
          </div>
          <TextField
            label="Username"
            name="username"
            color="black"
            required
            value={formData.username}
            onChange={handleInputChange}
          />
          <TextField
            required
            label="Password"
            variant="outlined"
            type={passwordType}
            name="password"
            prependIcon="eye"
            color="black"
            value={formData.password}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={showPassword} edge="end">
                    {passwordType === "text" ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            type="submit"
            color="error"
            disableElevation
            size="large"
            disabled={Object.values(formData).some((x) => x === "") || loading}
          >
            {!loading ? (
              "login"
            ) : (
              <ClipLoader
                color={"#ffffff"}
                loading={loading}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </Button>
          <div className="cta-actions">
            <Link to="/forgot-password">forgot password</Link>{" "}
            <Link to="/register">register here</Link>
          </div>
        </form>
      </div>
    </span>
  );
};

export default LoginContent;

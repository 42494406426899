import React, { useState, useRef } from "react";
import "./PlayCards.css";
import "../../DisplayItems/styles/CardView.css";
import Logo from "../../../Logos/logo.png";
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { editCard } from "../../../endpoints/FlashCards/functions";
import { Check, Close, ExitToApp, Help } from "@mui/icons-material";

const PlayCards = (props) => {
  const [flip, setFlip] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const cardRef = useRef(null);
  let card = props.playingCards[props.index];
  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  async function handleWrong(id) {
    if (!card.in_study_pile) {
      let body = {
        deck_id: card.deck.id,
        question: card.question,
        answer: card.answer,
        hint: card.hint ? card.hint : "",
        in_study_pile: true,
      };
      try {
        await editCard(id, props.token, body);
      } catch (error) {
        console.log("Error occured at handleWrong", error);
      }
    }
    nextQuestion();
  }

  async function handleRight(id) {
    if (card.in_study_pile) {
      let body = {
        deck_id: card.deck.id,
        question: card.question,
        answer: card.answer,
        hint: card.hint ? card.hint : "",
        in_study_pile: false,
      };
      try {
        await editCard(id, props.token, body);
      } catch (error) {
        console.log("Error occured at handleRight", error);
      }
    }
    nextQuestion();
  }
  function nextQuestion() {
    if (props.index + 1 < props.playingCards.length) {
      props.setIndex(props.index + 1);
    } else {
      props.setIndex(0);
    }
    setFlip(false);
    setOpen(false);
  }
  return (
    <div id="playing-mode">
      <div className="flash-card" onClick={() => setFlip(!flip)} ref={cardRef}>
        <div className="background">
          <img
            className="logo-image flash-card-logo"
            src={Logo}
            alt="Assessive Logo"
          />
          {card && card.hint ? (
            <Tooltip title={card ? card.hint : ""} open={open}>
              <button onClick={handleClick} className="hint">
                {open ? (
                  <Help sx={{ color: "inherit", fontSize: "1.7rem" }} />
                ) : (
                  <Help sx={{ color: "#7d7d7d", fontSize: "1.7rem" }} />
                )}
              </button>
            </Tooltip>
          ) : null}
        </div>
        {!flip ? (
          <div className="card-front">
            <p>{card ? card.question : ""}</p>
          </div>
        ) : (
          <div className="card-back">
            <h3>{card ? card.answer : ""}</h3>
          </div>
        )}
      </div>
      <div className="cta-buttons">
        <Button
          color="error"
          onClick={() => handleWrong(card.id)}
          startIcon={<Close sx={{ fontSize: "1.7rem" }} />}
        >
          <div className="button-text">Wrong</div>
        </Button>
        <Button
          color="black"
          onClick={() => navigate("/flashcards")}
          startIcon={<ExitToApp sx={{ fontSize: "1.7rem" }} />}
        >
          <div className="button-text">Exit</div>
        </Button>
        <Button
          color="success"
          onClick={() => handleRight(card.id)}
          startIcon={<Check sx={{ fontSize: "1.7rem" }} />}
        >
          <div className="button-text">Right</div>
        </Button>
      </div>
    </div>
  );
};

export default PlayCards;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Logos/logo.png";
import { forgotPassword } from "../../endpoints/Assessment/functions";
import { ClipLoader } from "react-spinners";
import "./UserAccess.css";
import { Button, TextField } from "@mui/material";
const ForgotContent = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result = await forgotPassword(formData);
    if (result && result.status === 200) {
      setFormData({ email: "", username: "", password: "" });
      navigate("/login");
    }
    setLoading(false);
  };
  return (
    <span className="access-container">
      <div className="form-container" name="rest-password-form">
        <form onSubmit={handleSubmit} id="forgot-form">
          <div className="access-header">
            <h2 className="form-items">reset pass</h2>
            <div>
              <img src={Logo} alt="Assessive Logo" />
            </div>
          </div>
          <p className="note" style={{ marginBottom: "0rem" }}>
            NOTE: Your password needs letters, numbers, and special characters!
          </p>
          <TextField
            label="Email"
            name="email"
            color="black"
            required
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <TextField
            label="Username"
            name="username"
            color="black"
            required
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
          <TextField
            label="New Password"
            name="password"
            color="black"
            required
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
          <Button
            color="error"
            variant="contained"
            size="large"
            type="submit"
            disableElevation
            disabled={Object.values(formData).some((x) => x === "") || loading}
          >
            {!loading ? (
              "submit"
            ) : (
              <ClipLoader
                color={"#ffffff"}
                loading={loading}
                size={25}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            )}
          </Button>
          <div className="text-center">
            <Link to="/login">login</Link>
          </div>
        </form>
      </div>
    </span>
  );
};

export default ForgotContent;
